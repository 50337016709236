import React from "react";

function Competition(isVisible) {
  return (
    <>
      <span className={isVisible}>
        <pre>{`
ПЪРВА НАГРАДА Награда на Кмета на
Община Стара Загора
Лауреат:  Диляна Христова Христова
„ОСЪЗНАВАНЕ“


ВТОРА НАГРАДА от Фондация „КОСМОС – Д.Брацов“
Стара Загора
Лауреат: Цонка Людмилова Христова   гр. Дряново,
„СЪН НА ЯВЕ“


ТРЕТА НАГРАДА от Народно читалище
„Николай Лилиев-2005” Стара Загора
Ирина Сергеева Радионова    гр.  Разград
„ЖИВОТЕ!“


ТРИ ОТЛИЧИЯ по 100 лв.:
От Дружество на писателите Стара Загора
Светлана Йонкова Стоянова Андреева    София
 „ШЕПОТ“
-----------------------------------

От  ЕТ „БИМЕД- Иванка Брацова” - Стара Загора
 Петрана Николаева Петрова   гр. Враца
„ПРОЛЕТТА СЕ ЗАВЪРНА”
------------------------------

От Народно читалище „Даскал Петър Иванов-1988“ – Стара Загора
 Ваня Георгиева Велева           Ямбол
 „СЛЕД ЗИМАТА“


КОНКУРСНО ЖУРИ:
Председател:  Атанас Ангелов Капралов ……………………
Членове:   Йордан Димитров Пеев ………………………
Виолета Колева Бончева …………………….
Йордан Цонев Атанасов ……………………..
Димитър Генов Брацов: ……………………….


НАГРАДЕНИ СТИХОТВОРЕНИЯ


1.	Диляна Христова Христова

ОСЪЗНАВАНЕ

Сякаш времето спъна се подло във моите крачки –
рухнах с цялата тежест на Космоса. Пулсът е тих.
И преди окончателно този живот да ме смачка,
ще му хвърля в лицето последния истински стих.

Този стих ще чернее, досущ като тлъста утайка,
загрозила сърцето на белия скъп порцелан.
И ще бликне в сълза от око на почернена майка,
скрила снимка на мъртвото чедо във мократа длан.

Този стих ще напиша с кръвта на пробитата вена.
Всяка дума ще срича за мен в генетичния код.
Но е тъжно – след мен ще е същата тази Вселена
и досущ ще е същият този напразен живот.

Аз не вярвам в Душата. Тя, моята, вече се пръсна.
Вярвам само в човека, към дупката който върви.
Няма нищо божествено в мен, че и аз да възкръсна.
Само няколко думи написах, а колко кърви…



2.	Цонка Людмилова Христова

СЪН НА ЯВЕ

Очи на спомени надничат в съня ми.
Очи среднощносини метличини.
Виждам мама, която отваря
широко прозорците
в пролетно утро, сладко като крем-карамел.
Усмивката ѝ полита
и каца върху люляка,
а той рони ароматни сълзи
и се обяснява в любов на чучулигата.
Ето го и татко – наперен гларус на плажа в Шабла.
Около него рояк дечица,
наръсени с шоколадови пръчици,
а в очите им се вълнува морето
и подскачат златни рибки.
Мимолетни устни докосват моите,
змиевидни пръсти ме прегръщат.

Докосва гърдите ми малка жива топчица,
уханна като житена питка.
Паля свещичка на баба и дядо
по черешова задушница
и сърцето ми проси милост от смъртта,
но тя окосява поляните на съня ми
и ме връща във утрото,
на което принадлежа...
И разбирам, че нищо от това не е мое -
животът си пише приказки на гърба ми,
а аз съм просто героиня в съновидения.
Но никак не искам да се будя,
за да продължа приказката наяве.
Ала отвън ухае на момини сълзи
и смъртта в ъгъла на времето
ми плете терлици от паяжини.



3.	Ирина Сергеева Радионова

ЖИВОТЕ!

Аз няма да призная, че съм стъпкана,
защото само слабите мърморят.
Но ето – ще ти дам да се разходиш
в тревата под краката на деня ми.
И нека е зелено и уютно
в деня ти с мойте две необозрения –
децата ми, мъжът ми – в стълкновение
със всичко, от което се страхувам.
Защо? Аз вече отговори нямам.
Но псувайки, замесвам днес и ангели.
Мърсувам из молитвени трептения
с оргазмите на хилядите дяволи,
поискали да бъда само тяхна.

Аз – ничия земя – да ме завземеш!
Аз – ничия жена – за обладаване!
Аз – майка нечия – от теб заченала!

Аз! В края на деня –
едно сираче.



ОТЛИЧЕНИ:

Светлана Йонкова (Стоянова Андреева)

ШЕПОТ

… доволно е, че даваш покривката и хляба,
		и ние в тебе, майко, ще умрем чужденци!
 „Отечество любезно“, Иван Вазов

Тук мирише на папрат, къпини и мечки.
Тук животът те гледа с безброй сетива!
В равнината от грижи захлупен, човече,
колко често към стръмното вдигаш глава?
Разговаряш ли с Бог –Той е бор преоблечен,
Той е подбел маскиран, кошута и пън…
Тук извира от всичко космичната вечност,
невидяна от тебе дори и насън.
Закачлива шишарка и звън на мушица
ти се смеят, но ти си безкрайно зает!
Проследи ли с очи отлетялата птица
към зората, разкъсала нощния креп?
Като пръснати гъби равнецът белее,
тишината говори, но чуваш ли ти?
Крачиш гордо, понеже си имаш идеи,
ала нямаш, човече, неземни мечти!
Да си беден сред толкова много богатство…
Но да литне безкрилият би ли могъл?
Завърни се, човече, от своето бягство,
че пръстта ти нашепва защо си дошъл.



Петрана Николаева Петрова

ПРОЛЕТТА СЕ ЗАВЪРНА

Пролетта се завърна. Разпрегна коне и ги пусна.
С топла млада ръка заподрежда небесната шир.
Сякаш пак да открехнеш живота е древно изкуство,
от което се ражда небето и множи се безспир.

Задъхтяха ливади, прибрали коси посивели
и в зелено изплакнали своите жадни очи.
Тишината отстъпи и се сви под априлските трели,
за да може отново звънът на живот да звучи.

Слезе бързо цветът на надеждата в ниското даже –
от билата измръзнали, вперили строги чела.
Никой вече не помни и не може на мен да разкаже
колко дълго е спал под студена завивка света.

Топлината развя своя порив оранжев и трепкащ
и изправи надеждата, влюбена в новия ден.
Пролетта се завърна. И в сърцето си първо я срещам,
за да стигне най-сетне дъха ѝ разбуждащ до мен.




Ваня Георгиева Велева

СЛЕД ЗИМАТА

Над корито, препълнило своя корем с дъждове,
се оглежда луната, в последната седмица трудна.
Тя говори с небето на своя език откровен
и разплаква два облака, дето от вчера са будни.

По реката се влачат вълните, подобно змии,
бързат щъркели, мерят с червени аршини полето.
И покълва коприва, а кос необята пили.
И се пълни с надежда и обич на пролет котлето.

Само вечер, когато земята се сгушва за сън
и прикрита зад хълма луната лъчите си реше,
самотата прегръща на люляк лилавия звън
и се моли щурецът за всеки, дори да е грешен.

И тогава се чува от лунни кристали сглобен
на вселенския разум фалцета, с любовни мелизми.
С мисълта си небесна той влива цвета си във мен
и връвта на тъгата, от старата зима отгризва
                `}</pre>
      </span>
    </>
  );
}

export default Competition;
