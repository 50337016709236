import React from "react";

function Message(isVisible) {
  return (
    <>
      <span className={isVisible}>
        <pre>{`
и редакцията на вестника организират
НАЦИОНАЛЕН ЛИТЕРАТУРЕН КОНКУРС
ЗА ЕДНО СТИХОТВОРЕНИЕ НА СВОБОДНА ТЕМА
КОНКУРСЪТ Е АНОНИМЕН
Стихотворението не трябва да бъде публикувано!
НАГРАДИ:
ПЪРВА НАГРАДА - 300 ЛЕВА
ВТОРА НАГРАДА - 200 ЛЕВА
ТРЕТА НАГРАДА - 100 ЛЕВА
ЩЕ БЪДАТ РАЗДАДЕНИ И ДВЕ ПООЩРЕНИЯ ПО 50 ЛЕВА
Начало на конкурса – 1 октомври;
последна дата – 15 ноември 2024 г.
Авторите изпращат стихотворението и
личните си данни в
отделни файлове на електронна поща:
konkurs_litglas@abv.bg
Резултатите ще бъдат обявени през
декември 2024 година
на сайта на вестника www.literaturenglas.com
и на страниците на вестник „Литературен глас”.

                `}</pre>
      </span>
    </>
  );
}

export default Message;
